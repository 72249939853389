var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      _c("ANotAllowed", {
        attrs: { notAllowed: _vm.notAllowed },
        on: { close: _vm.closeNotAllowed },
      }),
      this.isLoadingSpecialRemark
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { height: "700", width: "700" },
          on: {
            "click:outside": function () {
              this$1.isAdding = false
            },
          },
          model: {
            value: _vm.isAdding,
            callback: function ($$v) {
              _vm.isAdding = $$v
            },
            expression: "isAdding",
          },
        },
        [
          _vm.isAdding
            ? _c("ComponentAddSpecialRemark", {
                attrs: { companyId: this.companyId },
                on: {
                  finish: function ($event) {
                    return _vm.fetch()
                  },
                  close: function () {
                    this$1.isAdding = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { height: "700", width: "700" },
          on: {
            "click:outside": function () {
              this$1.isUpdating = false
              this$1.isUpdatingId = null
            },
          },
          model: {
            value: _vm.isUpdating,
            callback: function ($$v) {
              _vm.isUpdating = $$v
            },
            expression: "isUpdating",
          },
        },
        [
          _vm.isUpdating
            ? _c("ComponentUpdateSpecialRemark", {
                attrs: { id: this.isUpdatingId },
                on: {
                  finish: function ($event) {
                    return _vm.fetch()
                  },
                  close: function () {
                    this$1.isUpdating = false
                    this$1.isUpdatingId = null
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !this.isLoadingSpecialRemark
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between mb-5" },
                  [
                    _c("span", { staticClass: "text-h6" }, [
                      _vm._v(" Remark "),
                    ]),
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize white--text rounded",
                        attrs: { color: "blue lighten-1" },
                        on: { click: _vm.openNewModal },
                      },
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(" Add "),
                        ]),
                        _c("v-icon", [_vm._v(" mdi-plus ")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.specialRemark != null
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-carousel",
                          {
                            attrs: {
                              height: "auto",
                              width: "auto",
                              continuous: false,
                              "hide-delimiters": "",
                            },
                          },
                          _vm._l(_vm.specialRemark, function (item, index) {
                            return _c(
                              "v-carousel-item",
                              { key: index },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-end mb-4" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "custom-button text-capitalize",
                                        attrs: { outlined: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openUpdateModal(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v(" Edit ")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between my-2",
                                  },
                                  [
                                    _c("span", [_vm._v(" Title ")]),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.title != null &&
                                              item.title != "null"
                                              ? item.title
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between my-2",
                                  },
                                  [
                                    _c("span", [_vm._v(" Written by : ")]),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.writer_id != null &&
                                              item.writer_id != "null"
                                              ? item.username
                                              : item.excel_username
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between my-2",
                                  },
                                  [
                                    _c("span", [_vm._v(" Report date ")]),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.created_at != null
                                              ? _vm.convertTimeZone(
                                                  item.created_at
                                                )
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("v-textarea", {
                                  staticClass: "rounded-lg",
                                  attrs: {
                                    flat: "",
                                    solo: "",
                                    "no-resize": "",
                                    "background-color": "blue-grey lighten-5",
                                    readonly: "",
                                  },
                                  model: {
                                    value: item.remark,
                                    callback: function ($$v) {
                                      _vm.$set(item, "remark", $$v)
                                    },
                                    expression: "item.remark",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between mt-n4 mb-3",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-bold mt-2" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(index + 1) +
                                            " / " +
                                            _vm._s(_vm.specialRemark.length) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "custom-button text-capitalize",
                                        attrs: {
                                          outlined: "",
                                          to: {
                                            name: "PageCompaniesViewRemark",
                                            params: { id: item.company_id },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v(" Show All ")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", [
                      _c("span", [_vm._v("No special remark found")]),
                    ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("hr", { staticStyle: { border: "2px solid #F2F3F7" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }