var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "background-color": "white" } },
    [
      _c("AError", { attrs: { api: this.api } }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function ($event) {
            return _vm.$emit("finish")
          },
        },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.api.isLoading
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12", sm: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "py-4" },
                    [
                      _c(
                        "v-toolbar",
                        { staticClass: "secondary ma-4" },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "white--text text-h4" },
                            [_vm._v(" New invoice record ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2" },
                            [
                              _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        label: "Invoice no.",
                                      },
                                      model: {
                                        value: _vm.form.invoice_no,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "invoice_no", $$v)
                                        },
                                        expression: "form.invoice_no",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.requestInvoiceOptions,
                                        dense: "",
                                        outlined: "",
                                        label: "Client request invoice",
                                      },
                                      model: {
                                        value: _vm.form.request_invoice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "request_invoice",
                                            $$v
                                          )
                                        },
                                        expression: "form.request_invoice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        label: "Invoice to name",
                                      },
                                      model: {
                                        value: _vm.form.requester_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "requester_name",
                                            $$v
                                          )
                                        },
                                        expression: "form.requester_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        label: "Invoice to address",
                                      },
                                      model: {
                                        value: _vm.form.requester_address,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "requester_address",
                                            $$v
                                          )
                                        },
                                        expression: "form.requester_address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("ComponentDateModal", {
                                      attrs: {
                                        label: "Send date",
                                        data: _vm.form.send_date,
                                      },
                                      on: {
                                        updateDateData: function (data) {
                                          this$1.form.send_date = data
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-end" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", plain: "" },
                                        on: { click: _vm.closeModal },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: { click: _vm.validateInput },
                                      },
                                      [_vm._v(" Submit ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }